import "./index.scss";
import "./theme.less";
import "react-app-polyfill/ie9";
import * as serviceWorker from '@/components/LuckSheet/serviceWorker';

window.onload = async () => {
  const app = await import("./main");
  app.run();
  serviceWorker.unregister();
};
